



import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/components/contents/breadcrumb/Breadcrumb.vue';
import KiyakuContents from '@/components/contents/kiyaku/kiyaku.vue';

@Component({
  components: {
    Breadcrumb,
    KiyakuContents,
  },
})
export default class Kiyaku extends Vue {
  private breadcrumb: BreadcrumbArr = [
    {
      title: 'HOME',
      link: '/',
    },
    {
      title: 'ワーカーズフォーラムMOC サービスご利用規約',
      link: '',
    },
  ];
}
